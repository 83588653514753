import { MixedSchema, ValidationError } from "yup";
import ItemInterface from "~/api/items/entities/ItemInterface";
import { IItem } from "~/entities/item";
import {
  FieldInterface,
  createTranslatedErrors,
  FieldInfoInterface,
  transformRulesToSchema,
} from "~/entities/field";
import { validateField } from "./validate-field";
import { ItemValidationErrors } from "./interfaces";

export function validateItem(item: ItemInterface): ItemValidationErrors {
  const result: ItemValidationErrors = {};

  const invalidFields: FieldInterface[] = getInvalidFields(item.fields);
  if (!invalidFields.length) return result;

  for (const invalidField of invalidFields) {
    const validationErrors = validateField(invalidField);
    const translatedErrors = createTranslatedErrors(validationErrors);

    result[invalidField.label] = translatedErrors;
  }

  function getInvalidFields(fields: FieldInterface[]): FieldInterface[] {
    return fields.filter((field) => !!validateField(field).length);
  }

  return result;
}

export function validateRefactoredItem(
  item: IItem,
  fields: FieldInfoInterface[],
): ItemValidationErrors {
  const errors: ItemValidationErrors = {};
  const itemSchemaShape: Record<string, MixedSchema> = {};

  for (const field of fields) {
    itemSchemaShape[field.name] = transformRulesToSchema(field);
  }

  for (const fieldKey in itemSchemaShape) {
    try {
      const fieldSchema = itemSchemaShape[fieldKey];
      fieldSchema.validateSync(item.getDataProperty(fieldKey));

      if (fieldKey in errors) delete errors[fieldKey];
    } catch (error) {
      if (error instanceof ValidationError) {
        errors[fieldKey] = createTranslatedErrors(error.errors);
      }
    }
  }

  return errors;
}

