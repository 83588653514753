import { MixedSchema, ValidationError } from "yup";
import { FieldInterface, transformRulesToSchema } from "~/entities/field";

export function validateField(field: FieldInterface): string[] {
  try {
    const validationSchema: MixedSchema = transformRulesToSchema(field.info);

    validationSchema.validateSync(field.data);

    return [];
  } catch (error) {
    if (error instanceof ValidationError) {
      return error.errors;
    }

    throw error;
  }
}

